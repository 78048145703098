import { Reward } from "@worldwidewebb/client-quests";
import { Node } from "reactflow";
import { NodeType } from "../../models/nodeType";

export default function getRewardFromNodeData(node: Node<NodeType>): Reward {
  const {
    data: { nodeName, nodeData },
  } = node;

  switch (nodeName) {
    case "allegiance_and_amount": {
      return {
        allegiance: {
          allegianceId: nodeData.allegianceId,
          change: nodeData.allegianceAmount,
        },
      };
    }
    case "custom_item": {
      return {
        item: {
          itemName: nodeData.itemName,
          quantity: nodeData.quantity,
        },
      };
    }
    case "energy_reward": {
      return {
        energy: {
          energyId: nodeData.energyId,
          change: nodeData.change,
        },
      };
    }
    case "item_name_and_quantity": {
      return {
        item: {
          itemName: nodeData.itemName,
          quantity: nodeData.quantity,
        },
      };
    }
    case "loot_table_reward": {
      return {
        lootTable: {
          lootTableId: nodeData.lootTableId,
        },
      };
    }
    case "skill_and_experience_points": {
      return {
        skill: {
          skillId: nodeData.skillId,
          experience: nodeData.experiencePoints,
        },
      };
    }
    case "skill_and_levels": {
      return {
        skill: {
          skillId: nodeData.skillId,
          levels: nodeData.levels,
        },
      };
    }
    default: {
      return {};
    }
  }
}
