import React from "react";
import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { undo } from "./undo";
import { redo } from "./redo";
import { narrative } from "./narrative";
import { insertPlayerName } from "./insertPlayerName";
import { colourRed } from "./colourRed";
import { colourLime } from "./colourLime";
import { colourYellow } from "./colourYellow";
import { colourCyan } from "./colourCyan";
import { colourMagenta } from "./colourMagenta";
import { colourOrange } from "./colourOrange";
import { scribbleRainbow } from "./scribbleRainbow";
import { scribbleWave } from "./scribbleWave";
import { scribbleShake } from "./scribbleShake";
import { scribbleWobble } from "./scribbleWobble";
import { scribblePulse } from "./scribblePulse";
import { scribbleWheel } from "./scribbleWheel";
import { scribbleJitter } from "./scribbleJitter";
import { scribbleBlink } from "./scribbleBlink";
import { scribbleSetScale } from "./scribbleSetScale";
import { scribbleSetScaleStack } from "./scribbleSetScaleStack";
import { scribbleResetScale } from "./scribbleResetScale";
import { scribbleSlant } from "./scribbleSlant";
import { tagItem } from "./tagItem";
import { tagLocation } from "./tagLocation";
import { tagLore } from "./tagLore";
import { tagNpc } from "./tagNpc";

export type CommandCategory = "general"| "colour" | "effect" | "transform" | "game value" | "game entity";

export interface Command {
  category: CommandCategory;
  showText?: boolean;
  icon: React.ReactElement;
  name: string;
  tooltip: string;
  execute: (editorRef: ReactCodeMirrorRef) => void;
}

export const commands: Record<string, Command> = {
  undo,
  redo,
  narrative,
  colourRed,
  colourLime,
  colourYellow,
  colourCyan,
  colourMagenta,
  colourOrange,
  scribbleRainbow,
  scribbleWave,
  scribbleShake,
  scribbleWobble,
  scribblePulse,
  scribbleWheel,
  scribbleJitter,
  scribbleBlink,
  scribbleSetScale,
  scribbleSetScaleStack,
  scribbleResetScale,
  scribbleSlant,
  insertPlayerName,
  tagItem,
  tagLocation,
  tagLore,
  tagNpc,
};

export const getCommands = (): Command[] => {
  return Object.values(commands).map((command) => command);
};

export const getCommandsByCategory = (): Record<string, Command[]> => {
  return Object.values(commands).reduce((commandsByCategory: Record<string, Command[]>, command) => {
    commandsByCategory[command.category] ??= [];
    commandsByCategory[command.category].push(command);

    return commandsByCategory;
  }, {});
};
