import { Reward } from "@worldwidewebb/client-quests";
import { Edge, Node } from "reactflow";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";
import getDeduplicatedConnectedNodes from "./getDeduplicatedConnectedNodes";
import getRewardFromNodeData from "./getRewardFromNodeData";

const sourceNodeTypeHandlesMap: Record<string, string[]> = {
  allegiance_and_amount: ["reward", "allegiance_and_amount"],
  custom_item: ["reward", "item_name_and_quantity"],
  energy_reward: ["reward"],
  item_name_and_quantity: ["reward", "item_name_and_quantity"],
  loot_table_reward: ["reward"],
  skill_and_experience_points: ["reward", "skill_and_experience_points"],
  skill_and_levels: ["reward", "skill_and_levels"],
};

export default function getRewards(nodes: Node<NodeType>[], edges: Edge<EdgeType>[]): Reward[] {
  return [
    ...getDeduplicatedConnectedNodes(nodes, edges, "create_quest_log", sourceNodeTypeHandlesMap)
      .map(getRewardFromNodeData)
      .filter((r): r is Reward => !!r),
    ...getDeduplicatedConnectedNodes(nodes, edges, "grant_reward", sourceNodeTypeHandlesMap)
      .map(getRewardFromNodeData)
      .filter((r): r is Reward => !!r),
  ];
}
