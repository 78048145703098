import { QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function regenerateSpeakerPassageAudio(questId: string, nodeId: string, passageId: string) {
  try {
    const { data } = await questEditorApi.regeneratePassage(questId, nodeId, passageId, {});

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getSpeakerPassageAudio(questId: string, nodeId: string) {
  try {
    const { data } = await questEditorApi.getDialogPassages(questId, nodeId);

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
