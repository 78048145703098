import { QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getTranslatedChoices() {
  try {
    const { data } = await questEditorApi.getTranslatedChoices();

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
