import { LootTable, QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getLootTables(): Promise<LootTable[]> {
  try {
    const { data: lootTables } = await questEditorApi.getLootTables();

    return lootTables.sort(({ lootTableId: a }, { lootTableId: b }) => (a < b ? -1 : a > b ? 1 : 0));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
