import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Node, NodeProps, useNodeId, useStoreApi } from "reactflow";
import { NodeType, SourceHandle, TargetHandle } from "../../../models/nodeType";
import { Controller, useFieldArray, useForm, UseFormSetValue, UseFormWatch } from "react-hook-form";
import {
  Button,
  Center,
  ChakraProps,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ulid } from "ulid";
import { MdClose, MdDragHandle } from "react-icons/md";
import { useDnD } from "../../../hooks/useDnD";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { FlowNodeWithChildren } from "./FlowNode";
import Editor from "../../editor/Editor";
import SelectNpcWithLastUsed from "../../base/SelectNpcWithLastUsed";
import {
  Dialog,
  ChoiceWithId,
  SpeakerPassageWithId,
  PassageType,
  SpeakerType,
  Choice,
} from "@worldwidewebb/quest-shared/dist/dialog";
import SelectSpeakerType from "../../base/SelectSpeakerType";
import SelectPassageType from "../../base/SelectPassageType";
import SelectSomething from "../../base/SelectSomething";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { getNpc } from "../../../api/npcs/npcs";
import useRegenerateSpeakerPassageAudio from "../../../hooks/audio/useRegenerateSpeakerPassageAudio";
import { useParams } from "react-router";
import useUpdateQuestMetaDataAndNodeData from "../../../hooks/quests/useUpdateQuestMetaDataAndNodeData";
import { Form } from "react-router-dom";
import useSpeakerPassageAudio from "../../../hooks/audio/useSpeakerPassageAudio";
import Tooltip from "../../../ui/base/chakra/Tooltip";
import { useReactFlowProviderParent } from "../../../context/reactflow/ReactFlowProviderParent";

function watchSpeakerPassage(watch: UseFormWatch<Dialog>, index: number): SpeakerPassageWithId {
  return {
    passageId: watch(`speakerPassages.${index}.passageId`) || ulid(),
    passageType: watch(`speakerPassages.${index}.passageType`) || "dialog",
    passage: watch(`speakerPassages.${index}.passage`),
    passagePrompt: watch(`speakerPassages.${index}.passagePrompt`),
    speakerType: watch(`speakerPassages.${index}.speakerType`) || "none",
    speakerId: watch(`speakerPassages.${index}.speakerId`) || "last_used",
  };
}

function setSpeakerPassagePassageType(setValue: UseFormSetValue<Dialog>, index: number, passageType: PassageType) {
  setValue(`speakerPassages.${index}.passageType`, passageType as PassageType);

  setSpeakerPassageSpeakerType(setValue, index, "none");
}

function setSpeakerPassagePassage(setValue: UseFormSetValue<Dialog>, index: number, passage: string) {
  setValue(`speakerPassages.${index}.passage`, passage);
}

function setSpeakerPassagePassagePrompt(setValue: UseFormSetValue<Dialog>, index: number, passagePrompt: string) {
  setValue(`speakerPassages.${index}.passagePrompt`, passagePrompt);
}

function setSpeakerPassageSpeakerType(setValue: UseFormSetValue<Dialog>, index: number, speakerType: SpeakerType) {
  setValue(`speakerPassages.${index}.speakerType`, speakerType);

  setSpeakerPassageSpeakerId(setValue, index, "last_used");
}

function setSpeakerPassageSpeakerId(setValue: UseFormSetValue<Dialog>, index: number, speakerId: string) {
  setValue(`speakerPassages.${index}.speakerId`, speakerId);
}

interface SpeakerPassageProps extends ChakraProps {
  index: number;
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  speakerPassage: SpeakerPassageWithId;
  setSpeakerPassage: (speakerPassage: SpeakerPassageWithId) => void;
  // ...
  npcInputs: TargetHandle[];
}

function findNodeById(nodes: Node<NodeType>[], nodeId: string): Node<NodeType> | undefined {
  for (const node of nodes) {
    if (node.id === nodeId) {
      return node;
    }

    const data = node.data.nodeData;

    if (data && typeof data === "object" && "nodes" in data && Array.isArray(data.nodes)) {
      const result = findNodeById(data.nodes as Node<NodeType>[], nodeId);
      if (result) return result;
    }
  }

  return undefined;
}

const SpeakerPassage = ({
  color,
  index,
  updateIndex,
  removeIndex,
  speakerPassage,
  setSpeakerPassage,
  npcInputs,
}: SpeakerPassageProps) => {
  const { passageId, passage, passagePrompt, passageType, speakerId, speakerType } = speakerPassage;

  const { register, getValues, setValue, watch, handleSubmit } = useForm<SpeakerPassageWithId>({
    defaultValues: { passageId, passage, passagePrompt, passageType, speakerId, speakerType },
    mode: "onBlur",
  });

  const { handlerId, isDragging, ref, refPreview } = useDnD("speakerPassages", index, updateIndex);

  const { isUpdating, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const { id: questId = "" } = useParams();
  const nodeId = useNodeId();

  const { isRegeneratingSpeakerPassageAudio, regenerateSpeakerPassageAudio } = useRegenerateSpeakerPassageAudio(
    questId,
    nodeId ?? ""
  );

  const { isLoading, error, speakerPassageAudioBase64, speakerPassageAudioChecksum } = useSpeakerPassageAudio(
    questId,
    nodeId ?? "",
    passageId
  );

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const watchedPassage = watch("passage");
  const watchedPassagePrompt = watch("passagePrompt");
  const watchedPassageType = watch("passageType");
  const watchedSpeakerId = watch("speakerId");
  const watchedSpeakerType = watch("speakerType");

  const { store } = useReactFlowProviderParent();

  const toast = useToast();

  const handleUpdate = useCallback(() => {
    const nodes = store.getState().getNodes();
    const edges = store.getState().edges;

    if (nodeId == null) {
      return;
    }

    const node: Node<NodeType<Dialog>> | undefined = findNodeById(nodes, nodeId);

    if (node == null) {
      toast({
        title: "Not Found",
        description: "Update Subgraph To Refresh Dialog Node",
        status: "warning",
      });

      return;
    }

    let speakerPassage: SpeakerPassageWithId | undefined = node.data.nodeData.speakerPassages.find(
      (speakerPassage) => speakerPassage.passageId === passageId
    );

    if (speakerPassage == null) {
      speakerPassage = {
        passageId,
        passage: watchedPassage,
        passagePrompt: watchedPassagePrompt,
        passageType: watchedPassageType,
        speakerId: watchedSpeakerId,
        speakerType: watchedSpeakerType,
      };

      node.data.nodeData.speakerPassages = [
        ...node.data.nodeData.speakerPassages.slice(0, index),
        speakerPassage,
        ...node.data.nodeData.speakerPassages.slice(index),
      ];
    }

    speakerPassage.passage = watchedPassage;
    speakerPassage.passagePrompt = watchedPassagePrompt;
    speakerPassage.passageType = watchedPassageType;
    speakerPassage.speakerId = watchedSpeakerId;
    speakerPassage.speakerType = watchedSpeakerType;

    updateNodeData(node.data.nodeData);

    updateQuestMetaDataAndNodeData(
      {
        questId,
        questData: {
          nodes,
          edges,
        },
        partialQuest: {
          version: ulid(),
        },
      },
      {
        onSuccess: () => regenerateSpeakerPassageAudio(passageId),
      }
    );
  }, [
    toast,
    store,
    updateNodeData,
    questId,
    nodeId,
    watchedPassage,
    watchedPassagePrompt,
    watchedPassageType,
    watchedSpeakerId,
    watchedSpeakerType,
    updateQuestMetaDataAndNodeData,
    regenerateSpeakerPassageAudio,
  ]);

  const isRegenerating = isUpdating || isRegeneratingSpeakerPassageAudio;

  return (
    <Form onSubmit={handleSubmit(setSpeakerPassage)} onBlur={handleSubmit(setSpeakerPassage)}>
      <Flex borderColor={color} borderRadius={0} borderWidth={1} ref={refPreview} opacity={isDragging ? 0.25 : 1}>
        <Center p={1} pl={3} ref={ref} data-handler-id={handlerId} cursor={"move"}>
          <Icon as={MdDragHandle} />
        </Center>
        <Stack flexGrow={1}>
          <Flex p={2} ml={2} bg={color} alignItems={"center"} justifyContent={"flex-end"}>
            <IconButton
              size={"xs"}
              color={"black"}
              variant={"ghost"}
              icon={<Icon as={MdClose} />}
              aria-label={"delete speaker passage"}
              onClick={() => removeIndex(index)}
            />
          </Flex>
          <Stack p={2}>
            <SimpleGrid columns={3} spacing={2}>
              <SelectPassageType
                color={color}
                value={getValues("passageType")}
                setValue={(passageType) => setValue("passageType", passageType as PassageType)}
              />

              {passageType !== "continue" && (
                <SelectSpeakerType
                  color={color}
                  value={getValues("speakerType")}
                  setValue={(speakerType) => setValue("speakerType", speakerType as SpeakerType)}
                />
              )}

              {passageType !== "continue" && speakerType === "npc" && (
                <SelectNpcWithLastUsed
                  color={color}
                  value={getValues("speakerId")}
                  setValue={(speakerId) => setValue("speakerId", speakerId)}
                />
              )}

              {passageType !== "continue" && speakerType === "npc_input" && (
                <SelectSomething
                  color={color}
                  value={getValues("speakerId")}
                  setValue={(speakerInput) => setValue("speakerId", speakerInput)}
                  title={"NPC Input"}
                  values={Object.fromEntries([
                    ["last_used", "last used"],
                    ...npcInputs.map(({ label = "" }, index) => [index.toString(), label]),
                  ])}
                />
              )}
            </SimpleGrid>

            {passageType === "continue" && (
              <FormControl>
                <FormLabel>
                  <Text color={color} casing={"uppercase"}>
                    Passage
                  </Text>
                </FormLabel>

                <Editor
                  height={"4rem"}
                  borderColor={color}
                  borderRadius={0}
                  borderWidth={2}
                  value={getValues("passage")}
                  onChange={(passage) => setValue("passage", passage)}
                />
              </FormControl>
            )}

            {(passageType === "dialog" || passageType === "dialog_personalized") && (
              <FormControl>
                <FormLabel>
                  <Stack>
                    <HStack justifyContent={"space-between"}>
                      <Text color={color} casing={"uppercase"}>
                        Passage
                      </Text>

                      <HStack>
                        <Button
                          color={color}
                          variant={"outline"}
                          onClick={() => handleUpdate()}
                          isDisabled={isRegenerating}
                          isLoading={isRegenerating}
                        >
                          <Text color={color} casing={"uppercase"}>
                            Regenerate Audio
                          </Text>
                        </Button>

                        <audio id={passageId} src={`data:audio/wav;base64,${speakerPassageAudioBase64}`} />

                        <Tooltip color={color} label={speakerPassageAudioChecksum}>
                          <Button
                            color={color}
                            variant={"outline"}
                            onClick={() =>
                              (document?.getElementById(passageId) as HTMLAudioElement | undefined)?.play()
                            }
                            isDisabled={isLoading}
                            isLoading={isLoading}
                          >
                            <Text color={color} casing={"uppercase"}>
                              Play
                            </Text>
                          </Button>
                        </Tooltip>
                      </HStack>
                    </HStack>

                    {error?.message}
                  </Stack>
                </FormLabel>

                <Editor
                  borderColor={color}
                  borderRadius={0}
                  borderWidth={2}
                  value={getValues("passage")}
                  onChange={(passage) => setValue("passage", passage)}
                />
              </FormControl>
            )}

            {passageType === "dialog_ai" && (
              <FormControl>
                <FormLabel>
                  <Text color={color} casing={"uppercase"}>
                    Passage Prompt
                  </Text>
                </FormLabel>

                <Textarea
                  {...register("passagePrompt")}
                  borderColor={color}
                  borderRadius={0}
                  borderWidth={2}
                  minH={"3xs"}
                />
              </FormControl>
            )}
          </Stack>
        </Stack>
      </Flex>
    </Form>
  );
};

function watchChoice(watch: UseFormWatch<Dialog>, index: number): Choice {
  return {
    choice: watch(`choices.${index}.choice`),
    isConditional: watch(`choices.${index}.isConditional`),
  };
}

function setChoice(setValue: UseFormSetValue<Dialog>, index: number, choice: string) {
  setValue(`choices.${index}.choice`, choice);
}

function setChoiceIsConditional(setValue: UseFormSetValue<Dialog>, index: number, isConditional: boolean) {
  setValue(`choices.${index}.isConditional`, isConditional);
}

interface ChoiceComponentProps {
  index: number;
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  setValue: UseFormSetValue<Dialog>;
  watch: UseFormWatch<Dialog>;
  color?: string;
}

const ChoiceComponent: React.FC<ChoiceComponentProps> = ({
  index,
  updateIndex,
  removeIndex,
  setValue,
  watch,
  color,
}) => {
  const { handlerId, isDragging, ref, refPreview } = useDnD("choices", index, updateIndex);
  const { choice, isConditional } = watchChoice(watch, index);

  return (
    <Flex borderColor={color} borderRadius={0} borderWidth={1} ref={refPreview} opacity={isDragging ? 0.25 : 1}>
      <Center p={1} pl={3} ref={ref} data-handler-id={handlerId} cursor={"move"}>
        <Icon as={MdDragHandle} />
      </Center>
      <Stack flexGrow={1}>
        <Flex p={2} ml={2} bg={color} alignItems={"center"} justifyContent={"flex-end"}>
          <IconButton
            size={"xs"}
            color={"black"}
            variant={"ghost"}
            icon={<Icon as={MdClose} />}
            aria-label={"delete choice"}
            onClick={() => removeIndex(index)}
          />
        </Flex>
        <Stack p={2}>
          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Choice
              </Text>
            </FormLabel>

            <Input
              borderColor={color}
              borderRadius={0}
              borderWidth={2}
              placeholder={"choice text"}
              value={choice}
              onChange={({ target: { value } }) => setChoice(setValue, index, value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Available on Condition
              </Text>
            </FormLabel>

            <Checkbox
              color={color}
              isChecked={isConditional}
              onChange={({ target: { checked } }) => setChoiceIsConditional(setValue, index, checked)}
            />
          </FormControl>
        </Stack>
      </Stack>
    </Flex>
  );
};

interface DialogNodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  speakerPassages: SpeakerPassageWithId[];
  onUpdateSpeakerPassages: (speakerPassages: SpeakerPassageWithId[]) => void;
  choices: ChoiceWithId[];
  onUpdateChoices: (choices: ChoiceWithId[]) => void;
  clearScrollbackHistory: boolean;
  onUpdateClearScrollbackHistory: (clearScrollbackHistory: boolean) => void;
  color?: string;
  npcInputs: TargetHandle[];
}

export const DialogNodeModal: React.FC<DialogNodeModalProps> = ({
  isOpen,
  onClose,
  speakerPassages,
  onUpdateSpeakerPassages,
  choices,
  onUpdateChoices,
  clearScrollbackHistory,
  onUpdateClearScrollbackHistory,
  color,
  npcInputs,
}) => {
  const { control, register, reset, watch, setValue, handleSubmit } = useForm<Dialog>({
    defaultValues: useMemo(
      () => ({
        speakerPassages,
        choices,
        clearScrollbackHistory,
      }),
      [speakerPassages, choices, clearScrollbackHistory]
    ),
    mode: "onBlur",
  });

  const {
    fields: speakerPassageFields,
    insert: insertSpeakerPassage,
    remove: removeSpeakerPassage,
    move: moveSpeakerPassage,
  } = useFieldArray({
    name: "speakerPassages",
    control,
  });

  const {
    fields: choiceFields,
    insert: insertChoice,
    remove: removeChoice,
    move: moveChoice,
  } = useFieldArray({
    name: "choices",
    control,
  });

  const handleUpdate = useCallback(
    ({ speakerPassages, choices, clearScrollbackHistory }: Dialog) => {
      onUpdateSpeakerPassages(speakerPassages);
      onUpdateChoices(choices);
      onUpdateClearScrollbackHistory(clearScrollbackHistory);

      onClose();
    },
    [onClose, onUpdateSpeakerPassages, onUpdateChoices, onUpdateClearScrollbackHistory]
  );

  const watchedSpeakerPassages = watch("speakerPassages");

  const handleInsertSpeakerPassage = useCallback(
    (index: number) => {
      const speakerPassage = watchedSpeakerPassages
        .slice()
        .reverse()
        .find(({ passageType }) => passageType === "dialog" || passageType === "dialog_ai");

      const hasNpcInputs = npcInputs.length !== 0;

      const lastSpeakerType = speakerPassage?.speakerType ?? (hasNpcInputs ? "npc_input" : "npc");
      const lastSpeakerId = speakerPassage?.speakerId ?? "";

      insertSpeakerPassage(index, {
        passageId: ulid(),
        passageType: "dialog",
        passage: "",
        passagePrompt: "",
        speakerType: lastSpeakerType,
        speakerId: lastSpeakerId,
      });

      if (index !== 0) {
        insertSpeakerPassage(index, {
          passageId: ulid(),
          passageType: "continue",
          passage: "",
          passagePrompt: "",
          speakerType: "none",
          speakerId: "",
        });
      }
    },
    [insertSpeakerPassage, watchedSpeakerPassages, npcInputs]
  );

  const handleRemoveSpeakerPassage = useCallback(
    (index: number) => {
      if (speakerPassageFields.length === 1) {
        return;
      }

      removeSpeakerPassage(index);
    },
    [removeSpeakerPassage, speakerPassageFields]
  );

  const handleMoveSpeakerPassage = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveSpeakerPassage(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveSpeakerPassage]
  );

  const handleInsertChoice = useCallback(
    (index: number) => {
      insertChoice(index, {
        choiceId: ulid(),
        choice: "",
      });
    },
    [insertChoice]
  );

  const handleRemoveChoice = useCallback(
    (index: number) => {
      removeChoice(index);
    },
    [removeChoice]
  );

  const handleMoveChoice = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveChoice(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveChoice]
  );

  const handleCancel = useCallback(() => {
    reset({
      speakerPassages,
      choices,
      clearScrollbackHistory,
    });

    onClose();
  }, [reset, speakerPassages, choices, clearScrollbackHistory, onClose]);

  useEffect(() => {
    reset({
      speakerPassages,
      choices,
      clearScrollbackHistory,
    });
  }, [reset, speakerPassages, choices, clearScrollbackHistory]);

  useEffect(() => {
    if (speakerPassageFields.length !== 0) {
      return;
    }

    handleInsertSpeakerPassage(0);
  }, [handleInsertSpeakerPassage, speakerPassageFields]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />

      <form onSubmit={handleSubmit(handleUpdate)}>
        <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <Stack>
              <Text color={color} casing={"uppercase"}>
                Speaker Passages
              </Text>

              <Stack>
                {speakerPassageFields.length === 0 ? (
                  <Center>
                    <Text color={"white"}>No speaker passages (insert new)</Text>
                  </Center>
                ) : (
                  speakerPassageFields.map((speakerPassage, index) => (
                    <SpeakerPassage
                      key={speakerPassage.passageId}
                      color={color}
                      index={index}
                      updateIndex={handleMoveSpeakerPassage}
                      removeIndex={handleRemoveSpeakerPassage}
                      speakerPassage={speakerPassage}
                      setSpeakerPassage={(speakerPassage) => setValue(`speakerPassages.${index}`, speakerPassage)}
                      npcInputs={npcInputs}
                    />
                  ))
                )}
              </Stack>

              <Flex justifyContent={"flex-end"}>
                <Button onClick={() => handleInsertSpeakerPassage(speakerPassageFields.length)} variant={"outline"}>
                  <Text color={color} textTransform={"uppercase"}>
                    Insert New
                  </Text>
                </Button>
              </Flex>
            </Stack>

            <Stack>
              <Text color={color} casing={"uppercase"}>
                Choices
              </Text>

              <Stack>
                {choiceFields.length === 0 ? (
                  <Center>
                    <Text color={"white"}>No choices (insert new)</Text>
                  </Center>
                ) : (
                  choiceFields.map(({ choiceId }, index) => (
                    <ChoiceComponent
                      key={choiceId}
                      index={index}
                      updateIndex={handleMoveChoice}
                      removeIndex={handleRemoveChoice}
                      setValue={setValue}
                      watch={watch}
                      color={color}
                    />
                  ))
                )}
              </Stack>

              <Flex justifyContent={"flex-end"}>
                <Button onClick={() => handleInsertChoice(choiceFields.length)} variant={"outline"}>
                  <Text color={color} textTransform={"uppercase"}>
                    Insert New
                  </Text>
                </Button>
              </Flex>
            </Stack>

            <FormControl>
              <FormLabel>
                <Text color={color} casing={"uppercase"}>
                  Clear Scrollback History
                </Text>
              </FormLabel>
              <Checkbox {...register("clearScrollbackHistory")} />
            </FormControl>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button variant={"outline"} minW={"3xs"} color={"white"} onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant={"outline"} minW={"3xs"} color={color} type={"submit"}>
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

interface SpeakerComponentProps extends ChakraProps {
  speakerId: string;
  speakerType: SpeakerType;
  passageType: PassageType;
}

const SpeakerComponent: React.FC<SpeakerComponentProps> = ({ color, speakerId, speakerType, passageType }) => {
  const [npcDisplayName, setNpcDisplayName] = useState<string>();

  let speakerLabel = "no speaker";

  if (speakerType === "none") {
    speakerLabel = "no speaker";
  }

  if (speakerType === "player") {
    speakerLabel = "player";
  }

  if (speakerType === "npc") {
    speakerLabel = speakerId === "last_used" ? "last used" : npcDisplayName ?? "(npc not found)";
  }

  if (speakerType === "npc_input") {
    speakerLabel = speakerId === "last_used" ? "last used" : `NPC ${+speakerId + 1}`;
  }

  if (passageType === "continue") {
    speakerLabel = "continue";
  }

  if (passageType === "auto_continue") {
    speakerLabel = "auto continue";
  }

  useEffect(() => {
    if (speakerType !== "npc") {
      return;
    }

    getNpc(speakerId)
      .then(({ displayName }) => setNpcDisplayName(displayName))
      .catch((error) => console.error(error));
  }, [getNpc, speakerId, speakerType]);

  return (
    <Tag color={color} textTransform={"uppercase"}>
      {speakerLabel}
    </Tag>
  );
};

interface DialogNodePreviewProps extends ChakraProps {
  speakerPassages: SpeakerPassageWithId[];
  choices: ChoiceWithId[];
}

const DialogNodePreview: React.FC<DialogNodePreviewProps> = ({ color, speakerPassages, choices }) => {
  return (
    <Stack pb={4} spacing={4}>
      {speakerPassages.length !== 0 && (
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              Passages
            </Text>
          </FormLabel>

          <Stack>
            {speakerPassages.map(({ passageId, speakerId, speakerType, passage, passageType }) => (
              <Stack key={passageId}>
                <SpeakerComponent
                  color={color}
                  speakerId={speakerId}
                  speakerType={speakerType}
                  passageType={passageType}
                />

                <Text color={"white"} textAlign={"justify"} whiteSpace={"pre-wrap"} maxW={"md"}>
                  {passage}
                </Text>
              </Stack>
            ))}
          </Stack>
        </FormControl>
      )}

      {choices.length !== 0 && (
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              Choices
            </Text>
          </FormLabel>

          <Stack>
            {choices.map(({ choiceId, choice }, index) => (
              <HStack key={choiceId}>
                <Tag color={color}>{index}</Tag>

                <Text color={"white"} textAlign={"justify"} whiteSpace={"pre-wrap"} maxW={"md"}>
                  {choice}
                </Text>
              </HStack>
            ))}
          </Stack>
        </FormControl>
      )}
    </Stack>
  );
};

function SourceHandlesIncludingChoicesSelector({ handleName }: SourceHandle) {
  return handleName === "out" || isFinite(+handleName);
}

function SourceHandlesExcludingChoicesSelector({ handleName }: SourceHandle) {
  return handleName !== "out" && isNaN(+handleName);
}

function TargetHandlesIncludingChoicesSelector({ handleName }: TargetHandle) {
  return handleName === "boolean";
}

function TargetHandlesExcludingChoicesExcludingNpcsSelector({ handleName }: TargetHandle) {
  return handleName !== "npc" && handleName !== "boolean";
}

function TargetHandlesExcludingChoicesIncludingNpcsSelector({ handleName }: TargetHandle) {
  return handleName === "npc";
}

function TargetHandlesIncludingNpcsSelector({ handleName }: TargetHandle) {
  return handleName === "npc";
}

function TargetHandlesExcludingNpcsSelector({ handleName }: TargetHandle) {
  return handleName !== "npc";
}

const DialogNode: React.FC<NodeProps<NodeType<Dialog>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, sourceHandles = [], targetHandles = [] },
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const speakerPassages = nodeData?.speakerPassages ?? [];
  const choices = nodeData?.choices ?? [];
  const clearScrollbackHistory = nodeData?.clearScrollbackHistory ?? false;

  const npcInputCount = nodeData?.npcInputCount ?? 0;
  const npcInputs = targetHandles.filter(TargetHandlesIncludingNpcsSelector);

  const { control, handleSubmit } = useForm<Pick<Dialog, "npcInputCount">>({
    defaultValues: useMemo(
      () => ({
        npcInputCount,
      }),
      [npcInputCount]
    ),
    mode: "onChange",
  });

  const { updateNodeTargetHandles, updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdateChoiceSourceHandles = useCallback(
    (choices: ChoiceWithId[]) => {
      const currentSourceHandles = sourceHandles.filter(SourceHandlesIncludingChoicesSelector);

      // re-sort handles
      let updatedSourceHandles: SourceHandle[] = choices.map(
        ({ choiceId }, index) =>
          currentSourceHandles.find(({ linkedNodeDataId: linkedChoiceId }) => linkedChoiceId === choiceId) ?? {
            label: `Choice ${index.toString()}`,
            handleName: index.toString(),
            handleType: "source",
            handleCategory: "flow",
            linkedNodeDataId: choiceId,
          }
      );

      // re-name handles
      updatedSourceHandles = updatedSourceHandles.map((sourceHandle, index) => ({
        ...sourceHandle,
        label: `Choice ${index.toString()}`,
        handleName: index.toString(),
      }));

      // ensure preservation of edge and at least one flow output
      if (updatedSourceHandles.length === 0) {
        const [sourceHandle] = sourceHandles;

        updatedSourceHandles.push({
          handleId: sourceHandle?.handleId,
          label: "OUT",
          handleName: "out",
          handleType: "source",
          handleCategory: "flow",
          linkedNodeDataId: undefined,
        });
      }

      // ensure preservation of edge
      if (sourceHandles.some(({ handleName }) => handleName === "out")) {
        const [sourceHandle] = sourceHandles;

        updatedSourceHandles[0].handleId = sourceHandle.handleId;
      }

      updateNodeSourceHandles([
        ...updatedSourceHandles,
        ...sourceHandles.filter(SourceHandlesExcludingChoicesSelector),
      ]);
    },
    [sourceHandles, updateNodeSourceHandles]
  );

  const handleUpdateChoiceTargetHandles = useCallback(
    (choices: ChoiceWithId[]) => {
      const currentTargetHandles = targetHandles.filter(TargetHandlesIncludingChoicesSelector);

      // re-sort handles
      let updatedTargetHandles: TargetHandle[] = choices.map(
        ({ choiceId }, index) =>
          currentTargetHandles.find(({ linkedNodeDataId: linkedChoiceId }) => linkedChoiceId === choiceId) ?? {
            label: `Choice ${index.toString()} Condition`,
            handleName: "boolean",
            handleType: "target",
            handleCategory: "data",
            linkedNodeDataId: choiceId,
          }
      );

      // re-name handles
      updatedTargetHandles = updatedTargetHandles.map((sourceHandle, index) => ({
        ...sourceHandle,
        label: `Choice ${index.toString()} Condition`,
      }));

      // include isConditionals
      updatedTargetHandles = updatedTargetHandles.filter(({ linkedNodeDataId: linkedChoiceId }) =>
        choices.some(({ choiceId, isConditional }) => isConditional && choiceId === linkedChoiceId)
      );

      updateNodeTargetHandles([
        ...targetHandles.filter(TargetHandlesExcludingChoicesExcludingNpcsSelector),
        ...updatedTargetHandles,
        ...targetHandles.filter(TargetHandlesExcludingChoicesIncludingNpcsSelector),
      ]);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const handleUpdateNpcTargetHandles = useCallback(
    (npcInputCount: number) => {
      const currentNpcInputCount = targetHandles.filter(TargetHandlesIncludingNpcsSelector).length;

      // current handles
      let updatedTargetHandles: TargetHandle[] = targetHandles.filter(TargetHandlesIncludingNpcsSelector);

      if (currentNpcInputCount < npcInputCount) {
        const handlesToInsert = npcInputCount - currentNpcInputCount;

        updatedTargetHandles.push(
          ...[...Array(handlesToInsert)].map(
            (): TargetHandle => ({
              label: "NPC",
              handleName: "npc",
              handleType: "target",
              handleCategory: "data",
            })
          )
        );
      } else {
        const handlesToRemove = currentNpcInputCount - npcInputCount;

        if (handlesToRemove) {
          updatedTargetHandles = updatedTargetHandles.slice(0, -handlesToRemove);
        }
      }

      // re-name handles
      updatedTargetHandles = updatedTargetHandles.map((sourceHandle, index) => ({
        ...sourceHandle,
        label: `NPC ${index + 1}`,
      }));

      updateNodeTargetHandles([...targetHandles.filter(TargetHandlesExcludingNpcsSelector), ...updatedTargetHandles]);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdateSpeakerPassages = useCallback(
    (speakerPassages: SpeakerPassageWithId[]) => {
      updateNodeData({
        speakerPassages,
      });
    },
    [updateNodeData]
  );

  const handleUpdateChoices = useCallback(
    (choices: ChoiceWithId[]) => {
      updateNodeData({
        choices,
      });

      handleUpdateChoiceSourceHandles(choices);
      handleUpdateChoiceTargetHandles(choices);
    },
    [updateNodeData, handleUpdateChoiceSourceHandles, handleUpdateChoiceTargetHandles]
  );

  const handleUpdateClearScrollbackHistory = useCallback(
    (clearScrollbackHistory: boolean) => {
      updateNodeData({
        clearScrollbackHistory,
      });
    },
    [updateNodeData]
  );

  const handleUpdateNpcInputCount = useCallback(
    ({ npcInputCount }: Pick<Dialog, "npcInputCount">) => {
      updateNodeData({
        npcInputCount,
      });

      handleUpdateNpcTargetHandles(npcInputCount);
    },
    [updateNodeData, handleUpdateNpcTargetHandles]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <DialogNodePreview color={color} speakerPassages={speakerPassages} choices={choices} />

        <Stack>
          <form
            onSubmit={handleSubmit(handleUpdateNpcInputCount)}
            onBlur={handleSubmit(handleUpdateNpcInputCount)}
            onChange={handleSubmit(handleUpdateNpcInputCount)}
          >
            <FormControl>
              <FormLabel>
                <Text color={color} casing={"uppercase"}>
                  NPC Input Count
                </Text>
              </FormLabel>
              <Controller
                name={"npcInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper color={color} />
                      <NumberDecrementStepper color={color} />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>
          </form>

          <Button w={"100%"} onClick={onOpen} variant={"outline"}>
            <Text color={color} textTransform={"uppercase"}>
              Editor
            </Text>
          </Button>
        </Stack>
      </FlowNodeWithChildren>

      <DialogNodeModal
        isOpen={isOpen}
        onClose={onClose}
        speakerPassages={speakerPassages}
        onUpdateSpeakerPassages={handleUpdateSpeakerPassages}
        choices={choices}
        onUpdateChoices={handleUpdateChoices}
        clearScrollbackHistory={clearScrollbackHistory}
        onUpdateClearScrollbackHistory={handleUpdateClearScrollbackHistory}
        color={color}
        npcInputs={npcInputs}
      />
    </>
  );
};

export default memo(DialogNode);
