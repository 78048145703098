import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { EditorSelection } from "@codemirror/state";

export const insertSurroundingText = ({ state, view }: ReactCodeMirrorRef, beginText: string, endText: string, cursorOffset?: number) => {
  if (state == null) {
    return;
  }

  if (view == null) {
    return;
  }

  view.dispatch(
    view.state.changeByRange(({ from, to }) => {
      let range = EditorSelection.range(from + beginText.length, to + endText.length);

      if (cursorOffset) {
        range = EditorSelection.cursor(from + cursorOffset);
      }

      return {
        changes: [
          { from, insert: beginText },
          { from: to, insert: endText },
        ],
        range,
      };
    })
  );

  view.focus();
};
