import { Button, Text, useToast } from "@chakra-ui/react";
import { memo, useCallback, useState } from "react";
import { getTranslatedChoices } from "../../api/translations/translatedChoices";
import { downloadXlsxFile } from "../../utils/downloadXlsxFile";

function DownloadTranslatedChoicesButton() {
  const toast = useToast();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);

    getTranslatedChoices()
      .then((translatedChoices) => {
        setTimeout(() => {
          downloadXlsxFile(translatedChoices);

          setIsDownloading(false);
        }, 0);
      })
      .catch((error) => {
        toast({
          title: "Failed to download translated choices",
          description: (error as Error).message,
          status: "error",
        });

        setIsDownloading(false);
      });
  }, [toast]);

  return (
    <Button color={"white"} variant={"outline"} onClick={handleDownload} isLoading={isDownloading}>
      <Text color={"white"} casing={"uppercase"}>
        Download Translated Choices
      </Text>
    </Button>
  );
}

export default memo(DownloadTranslatedChoicesButton);
