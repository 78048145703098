import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { FaPenNib } from "react-icons/fa";
import { insertSurroundingText } from "../utils/insertSurroundingText";

export const narrative: Command = {
  category: "general",
  showText: true,
  icon: <Icon as={FaPenNib} />,
  name: "narrative",
  tooltip: "tag with narrative",
  execute: (editorRef) => insertSurroundingText(editorRef, `{{#narrative ""}}`, `{{/narrative}}`, `{{#narrative "`.length),
};
