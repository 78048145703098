import { Button, Text, useToast } from "@chakra-ui/react";
import { memo, useCallback, useState } from "react";
import { getTranslatedSpeakerPassages } from "../../api/translations/translatedSpeakerPassages";
import { downloadXlsxFile } from "../../utils/downloadXlsxFile";

function DownloadTranslatedSpeakerPassagesButton() {
  const toast = useToast();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);

    getTranslatedSpeakerPassages()
      .then((translatedSpeakerPassages) => {
        setTimeout(() => {
          downloadXlsxFile(translatedSpeakerPassages);

          setIsDownloading(false);
        }, 0);
      })
      .catch((error) => {
        toast({
          title: "Failed to download translated speaker passages",
          description: (error as Error).message,
          status: "error",
        });

        setIsDownloading(false);
      });
  }, [toast]);

  return (
    <Button color={"white"} variant={"outline"} onClick={handleDownload} isLoading={isDownloading}>
      <Text color={"white"} casing={"uppercase"}>
        Download Translated Speaker Passages
      </Text>
    </Button>
  );
}

export default memo(DownloadTranslatedSpeakerPassagesButton);
