import { useQuery } from "@tanstack/react-query";
import { LootTable } from "@worldwidewebb/client-quests";
import { getLootTables } from "../../api/lootTables/lootTables";

export default function useLootTables() {
  const {
    isLoading,
    isFetching,
    error,
    data: lootTables,
  } = useQuery<LootTable[]>({
    queryKey: ["lootTable"],
    queryFn: () => getLootTables(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    lootTables,
  };
}
