import { Edge, Node } from "reactflow";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";

export default function mapNodesByConnectedHandleName(
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[],
  targetHandleName: string,
  targetNodeType: string,
  sourceNodeType: string
): Node<NodeType>[] {
  return nodes
    .filter(({ data: { nodeName } }) => nodeName === targetNodeType)
    .flatMap(({ data: { targetHandles = [] } }) => targetHandles)
    .filter(({ handleName }) => handleName === targetHandleName)
    .flatMap(({ handleId: targetHandleId }) =>
      nodes
        .filter(({ data: { nodeName } }) => nodeName === sourceNodeType)
        .filter(({ data: { sourceHandles = [] } }) =>
          sourceHandles.some(({ handleId: sourceHandleId }) => {
            return edges
              .filter(({ sourceHandle }) => sourceHandle === sourceHandleId)
              .map(({ targetHandle }) => targetHandle)
              .includes(targetHandleId);
          })
        )
    );
}
