import { Edge, Node } from "reactflow";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";
import mapNodesByConnectedHandleName from "./mapNodesByConnectedHandleName";

export default function getDeduplicatedConnectedNodes(
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[],
  targetNodeType: string,
  sourceNodeTypeHandlesMap: Record<string, string[]>
): Node<NodeType>[] {
  const connectedNodes = Object.entries(sourceNodeTypeHandlesMap).flatMap(([sourceNodeType, targetHandleNames]) =>
    targetHandleNames.flatMap((targetHandleName) =>
      mapNodesByConnectedHandleName(nodes, edges, targetHandleName, targetNodeType, sourceNodeType)
    )
  );

  return [...new Map(connectedNodes.map((connectedNode) => [connectedNode.id, connectedNode])).values()];
}
