import { createContext, PropsWithChildren, useContext } from "react";
import { Edge, Node, ReactFlowState, useStore, useStoreApi } from "reactflow";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";

interface ContextType {
  store: {
    getState: () => ReactFlowState;
    setState: (
      partial:
        | ReactFlowState
        | Partial<ReactFlowState>
        | ((state: ReactFlowState) => ReactFlowState | Partial<ReactFlowState>),
      replace?: boolean | undefined
    ) => void;
    subscribe: (listener: (state: ReactFlowState, prevState: ReactFlowState) => void) => () => void;
    destroy: () => void;
  };
}

const Context = createContext<ContextType | null>(null);

export function ReactFlowProviderParent({ children }: PropsWithChildren) {
  const store = useStoreApi();

  return <Context.Provider value={{ store }}>{children}</Context.Provider>;
}

export function useReactFlowProviderParent() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useReactFlowProviderParent used outside of ReactFlowProviderParent");
  }

  return context;
}
