import React, { memo, useCallback, useMemo } from "react";
import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { Command } from "../commands";
import { Button, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface CommandToolbarProps {
  editorRef: React.RefObject<ReactCodeMirrorRef>;
  commands: Command[];
}

const CommandToolbar: React.FC<CommandToolbarProps> = ({ editorRef, commands }) => {
  const generalCommands = useMemo(() => commands.filter(({ category }) => category === "general"), [commands]);

  const commandsGroupedByCategory = useMemo(
    () =>
      Object.entries(
        commands.reduce((commandsGroupedByCategory: Record<string, Command[]>, command: Command) => {
          if (command.category === "general") {
            return commandsGroupedByCategory;
          }

          commandsGroupedByCategory[command.category] ??= [];
          commandsGroupedByCategory[command.category].push(command);

          return commandsGroupedByCategory;
        }, {})
      ),
    [commands]
  );

  const handleClick = useCallback(
    (execute: Command["execute"]) => {
      if (editorRef.current == null) {
        return;
      }

      execute(editorRef.current);
    },
    [editorRef]
  );

  return (
    <Flex py={1} gap={1} wrap={"wrap"} alignItems={"center"}>
      {generalCommands.map(({ icon, name, tooltip, execute, showText }) => (
        <Tooltip key={name} label={tooltip} placement={"top"}>
          <Button color={"white"} size={"xs"} aria-label={tooltip} onClick={() => handleClick(execute)}>
            <HStack>
              {icon}

              {showText && (
                <Text color={"white"} casing={"uppercase"}>
                  {name}
                </Text>
              )}
            </HStack>
          </Button>
        </Tooltip>
      ))}

      {commandsGroupedByCategory.map(([category, commands]) => (
        <Menu key={category}>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={"xs"} color={"white"}>
            <Text color={"white"} textTransform={"uppercase"}>
              {category}
            </Text>
          </MenuButton>
          <MenuList borderWidth={2} borderColor={"theme.dark.background"} bg={"theme.dark.background"} p={0.5}>
            {commands.map(({ icon, name, tooltip, execute, category }) =>
              category === "colour" ? (
                <Tooltip key={name} label={tooltip} placement={"right"}>
                  <MenuItem color={name} icon={icon} onClick={() => handleClick(execute)}>
                    <Skeleton h={2} startColor={name} endColor={name}></Skeleton>
                  </MenuItem>
                </Tooltip>
              ) : (
                <Tooltip key={name} label={tooltip} placement={"right"}>
                  <MenuItem icon={icon} onClick={() => handleClick(execute)}>
                    <Text color={"white"} casing={"uppercase"}>
                      {name}
                    </Text>
                  </MenuItem>
                </Tooltip>
              )
            )}
          </MenuList>
        </Menu>
      ))}
    </Flex>
  );
};

export default memo(CommandToolbar);
