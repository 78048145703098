import { useQuery } from "@tanstack/react-query";
import { PassageVoiceOver } from "@worldwidewebb/client-quests";
import { useMemo } from "react";
import { getSpeakerPassageAudio } from "../../api/audio/audio";

export default function useSpeakerPassageAudio(questId: string, nodeId: string, passageId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: speakerPassageAudios,
  } = useQuery<PassageVoiceOver[]>({
    queryKey: ["speakerPassage", questId, nodeId],
    queryFn: () => getSpeakerPassageAudio(questId, nodeId),
  });

  const speakerPassageAudio = speakerPassageAudios?.find(
    (speakerPassageAudio) => speakerPassageAudio.passageId === passageId
  );

  const speakerPassageAudioBase64 = speakerPassageAudio?.audioBase64;
  const speakerPassageAudioChecksum = speakerPassageAudio?.checksum;

  return {
    isLoading: isLoading || isFetching,
    error: error || speakerPassageAudio?.error ? new Error(speakerPassageAudio?.error) : null,
    speakerPassageAudio,
    speakerPassageAudioBase64,
    speakerPassageAudioChecksum,
  };
}
