import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { regenerateSpeakerPassageAudio as regenerateSpeakerPassageAudioAsync } from "../../api/audio/audio";

export default function useRegenerateSpeakerPassageAudio(questId: string, nodeId: string) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const {
    error,
    isPending: isRegeneratingSpeakerPassageAudio,
    mutate: regenerateSpeakerPassageAudio,
  } = useMutation({
    mutationFn: (passageId: string) => regenerateSpeakerPassageAudioAsync(questId, nodeId, passageId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["speakerPassage", questId, nodeId],
      });

      toast({ title: "Speaker passage regeneration successful", status: "success" });
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        toast({
          title: "Speaker passage regeneration unsuccessful",
          status: "error",
          description: `${error.response?.data.message}, is the quest in draft?`,
        });
      } else {
        toast({ title: "Speaker passage regeneration unsuccessful", status: "error", description: error.message });
      }
    },
  });

  return { error, isRegeneratingSpeakerPassageAudio, regenerateSpeakerPassageAudio };
}
